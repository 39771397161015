<template>
  <div>
    <div class="pageHeader" style="overflow: hidden">
      <div style="float:left">
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
      </div>
      <div style="float:right;">
        <el-input placeholder="名称/编号" v-model="keyword" class="input-with-select" style="width:350px;"></el-input>&nbsp;
        <el-button type="primary" @click="handle_search()" icon="el-icon-search">搜索</el-button>
      </div>
    </div>

    <el-table :data="page.list" v-loading="showLoading" element-loading-text="拼命加载中" border width="width:100%; height:50%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="schemeno" label="方案编号" width="150"></el-table-column>
      <el-table-column prop="name" label="方案名称" width="150"></el-table-column>
      <el-table-column prop="customer.name" label="客户" width="150"></el-table-column>
      <el-table-column prop="order.orderno" label="订单" width="150"></el-table-column>
      <el-table-column prop="downloadcnt" label="下载次数" width="100"></el-table-column>
      <el-table-column prop="reviewcnt" label="预览次数" width="100"></el-table-column>
      <el-table-column prop="status" label="状态" width="50"></el-table-column>
      <el-table-column prop="scope" label="操作" min-width="100">
        <template  #default="scope">
          <el-button v-if="auth['scheme:view']" type="text" icon="el-icon-view" @click="handler_schemereview(scope.row)">查看</el-button>
          <el-button v-if="auth['scheme:download']" type="text" icon="el-icon-download" @click="handler_schemedownload(scope.row.id)">下载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="devlogChange"
                   :current-page="page.pageNum"
                   :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                   :total="page.total">
    </el-pagination>

    <el-dialog
        title="阅览方案"
        :close-on-click-modal="false"
        width="1000px"
        class="dialog"
        v-model="showSchemeInfoDialog"
        @close="showSchemeInfoDialog = false"
    >
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">客户</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.custid }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">方案名称</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.name }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">方案内容</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.content }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">审核意见</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.examopinion }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">审核人id</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.examuid }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">审核人时间</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.examtime }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">下载次数</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.downloadcnt }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">预览次数</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.reviewcnt }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">创建时间</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.addtime }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">创建人id</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.addempid }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">更新时间</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.updatetime }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span class="fr">更新人id</span>
        </el-col>
        <el-col :span="20">
          {{ schemeInfo.updateempid }}
        </el-col>
      </el-row>
      <div class="row"></div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showSchemeInfoDialog = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SCHE from '../../api/biz/api_scheme';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";
import ORDER from "@/api/biz/api_order";
import SCHEME from "@/api/biz/api_scheme";

export default {
  name: "schemelist", // 方案列表
  props: {
    keyword: {
      type: String
    }
  },
  data() {
    return {
      showLoading: false,
      page: {list: [], pageNum: 1, pageSize: 10, total: 0},
      schemeInfo: {},
      showSchemeInfoDialog: false,
      auth: {}
    }
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.auth=this.$getAuth('/scheme');
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;

      // 执行查询
      SCHE.list({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        keyword: this.keyword
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    // 查询
    handle_search() {
      this.initPageData();
    },

    handler_schemedownload(schemeid) {
      try {
        SCHEME.download({ id: schemeid })
            .then((msg) => {
              let link = document.createElement("a");
              link.style.display = "none";
              link.href = window.URL.createObjectURL(new Blob([msg]));
              link.setAttribute("download", this.schemeInfo?.name + ".csv");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.$message.success("操作成功！");
            })
            .catch((error) => {
              console.error("promise catch err for download");
            });
      } catch (err) {
        console.warn("promise catch err");
      }
    },

    handler_schemereview(row) {
      this.showSchemeInfoDialog = true;
      this.schemeInfo = row;
      let params = { id: row.id };

      SCHEME.review(params).then((res) => {
        if (200 == res?.code) {
          this.schemeInfo = res.data;
        } else {
          this.$message.error(res?.msg);
        }
      });
    }
  }
}
</script>

<style scoped>
.pageHeader {
  background-color: white;
  border-bottom: 1px solid #CCCCCC;
  font-size: 18px;
  padding: 5px 10px;
}

.red {
  color: #ff0000;
}
</style>